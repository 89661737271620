<template>
  <div
    class="page-template page-template-page-template-blank page-template-page-template-blank-php page page-id-29951 custom-background theme-Divi woocommerce-no-js et_button_no_icon et_pb_button_helper_class et_pb_gutter windows et_pb_gutters3 et_pb_pagebuilder_layout et_smooth_scroll et_no_sidebar et_divi_theme et-db"
  >
    <div id="page-container">
      <div id="main-content">
        <article
          id="post-29951"
          class="post-29951 page type-page status-publish hentry"
        >
          <div class="entry-content">
            <div id="et-boc" class="et-boc">
              <div class="et-l et-l--post">
                <div class="et_builder_inner_content et_pb_gutters3">
                  <div
                    class="et_pb_section et_pb_section_0 et_pb_with_background et_section_regular"
                  >
                    <div
                      class="et_pb_row et_pb_row_0 et_pb_equal_columns et_pb_gutters2"
                    >
                      <div
                        class="et_pb_column et_pb_column_1_2 et_pb_column_0 et_pb_css_mix_blend_mode_passthrough"
                      >
                        <div
                          class="et_pb_module et_pb_number_counter et_pb_number_counter_0 et_pb_text_align_center et_pb_bg_layout_light et_pb_with_title"
                          data-number-value="404"
                          data-number-separator=""
                        >
                          <div class="percent">
                            <p>
                              <span class="percent-value">Thank You</span
                              ><span class="percent-sign"></span>
                            </p>
                          </div>
                          <h2 class="title">for your booking</h2>
                        </div>
                        <div
                          class="et_pb_module et_pb_text et_pb_text_0 et_animated et_pb_text_align_left et_pb_bg_layout_light"
                          style="margin-top: 50px !important"
                        >
                          <div class="et_pb_text_inner">
                            <h1>
                              We can't wait to welcome you to a <br />
                              <strong>world of luxury and tranquility.</strong>
                            </h1>
                          </div>
                        </div>
                        <div
                          class="et_pb_button_module_wrapper et_pb_button_0_wrapper et_pb_button_alignment_center et_pb_module"
                          style="
                            text-align: center !important;
                            margin-top: 20px !important;
                          "
                        >
                          <router-link
                            class="et_pb_button et_pb_button_0 et_animated et_pb_bg_layout_light"
                            to="/dashboard"
                            >GO TO DASHBOARD</router-link
                          >
                        </div>
                      </div>
                      <div
                        class="et_pb_column et_pb_column_1_2 et_pb_column_1 et_pb_css_mix_blend_mode_passthrough et-last-child et_pb_column_empty"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.et_pb_button {
  font-size: 20px;
  font-weight: 500;
  padding: 0.3em 1em;
  line-height: 1.7em !important;
  background-color: transparent;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px solid;
  border-radius: 3px;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: all !important;
  transition-property: all !important;
}

.et_pb_button,
.et_pb_button_inner {
  position: relative;
}

.et_pb_button:hover,
.et_pb_module .et_pb_button:hover {
  border: 2px solid transparent;
  padding: 0.3em 2em 0.3em 0.7em;
}

.et_pb_button:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.et_pb_bg_layout_light.et_pb_button:hover,
.et_pb_bg_layout_light .et_pb_button:hover {
  background-color: #fff !important;
  border: 3px solid #7da741 !important;
  color: #7da741 !important;
  box-shadow: inset -2px -4px 20px 2px #93ae6b !important;
}

.et_pb_button:after,
.et_pb_button:before {
  font-size: 32px;
  line-height: 1em;
  content: "\35";
  opacity: 0;
  position: absolute;
  margin-left: -1em;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  text-transform: none;
  -webkit-font-feature-settings: "kern" off;
  font-feature-settings: "kern" off;
  font-variant: none;
  font-style: normal;
  font-weight: 400;
  text-shadow: none;
}

.et_pb_button.et_hover_enabled:hover:after,
.et_pb_button.et_pb_hovered:hover:after {
  -webkit-transition: none !important;
  transition: none !important;
}

.et_pb_button:before {
  display: none;
}

.et_pb_button:hover:after {
  opacity: 1;
  margin-left: 0;
}

.et_pb_module.et_pb_text_align_left {
  text-align: left;
}

.et_pb_module.et_pb_text_align_center {
  text-align: center;
}

.et_pb_module.et_pb_text_align_right {
  text-align: right;
}

.et_pb_module.et_pb_text_align_justified {
  text-align: justify;
}

.et_pb_bg_layout_light .et_pb_more_button {
  color: #2ea3f2;
}

.et_builder_inner_content {
  position: relative;
  z-index: 1;
}

header .et_builder_inner_content {
  z-index: 2;
}

.et_pb_css_mix_blend_mode_passthrough {
  mix-blend-mode: unset !important;
}

.et_pb_with_background {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.post-password-required .et_pb_row {
  padding: 0;
  width: 100%;
}

.post-password-required .et_password_protected_form {
  min-height: 0;
}

body.et_pb_pagebuilder_layout.et_pb_show_title
  .post-password-required
  .et_password_protected_form
  h1,
body:not(.et_pb_pagebuilder_layout)
  .post-password-required
  .et_password_protected_form
  h1 {
  display: none;
}

.et_pb_module.et_pb_section_parallax,
.et_pb_section_parallax.et_pb_column .et_pb_module,
.et_pb_section_parallax.et_pb_row .et_pb_column,
.et_pb_section_parallax.et_pb_row .et_pb_module {
  z-index: 9;
  position: relative;
}

.et_pb_preload,
.et_pb_section.et_pb_section_video.et_pb_preload {
  position: relative;
  background: #464646 !important;
}
.et_pb_section > .box-shadow-overlay ~ .et_pb_row {
  z-index: 11;
}

.et_pb_row > .box-shadow-overlay {
  z-index: 8;
}

header.et-l.et-l--header:after {
  clear: both;
  display: block;
  content: "";
}

.et_pb_module {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

@-webkit-keyframes fadeBottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@media (min-width: 981px) {
  .et_pb_section .et_pb_row .et_pb_column .et_pb_module.et-last-child,
  .et_pb_section .et_pb_row .et_pb_column .et_pb_module:last-child,
  .et_pb_section.et_section_specialty
    .et_pb_row
    .et_pb_column
    .et_pb_column
    .et_pb_module.et-last-child,
  .et_pb_section.et_section_specialty
    .et_pb_row
    .et_pb_column
    .et_pb_column
    .et_pb_module:last-child,
  .et_pb_section.et_section_specialty
    .et_pb_row
    .et_pb_column
    .et_pb_row_inner
    .et_pb_column
    .et_pb_module.et-last-child,
  .et_pb_section.et_section_specialty
    .et_pb_row
    .et_pb_column
    .et_pb_row_inner
    .et_pb_column
    .et_pb_module:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 980px) {
  .et_pb_column .et_pb_module {
    margin-bottom: 30px;
  }

  .et_pb_row .et_pb_column .et_pb_module.et-last-child,
  .et_pb_row .et_pb_column .et_pb_module:last-child,
  .et_section_specialty .et_pb_row .et_pb_column .et_pb_module.et-last-child,
  .et_section_specialty .et_pb_row .et_pb_column .et_pb_module:last-child {
    margin-bottom: 0;
  }

  .et_pb_module .et_pb_bg_layout_light_tablet.et_pb_button {
    color: #2ea3f2 !important;
  }

  .et_pb_module.et_pb_text_align_left-tablet {
    text-align: left;
  }

  .et_pb_module.et_pb_text_align_center-tablet {
    text-align: center;
  }

  .et_pb_module.et_pb_text_align_right-tablet {
    text-align: right;
  }

  .et_pb_module.et_pb_text_align_justified-tablet {
    text-align: justify;
  }
}

@media (max-width: 767px) {
  .et_pb_row_0 {
    width: 100% !important;
    max-width: 100% !important;
  }
  .percent-value {
    font-size: 60px !important;
  }

  .et_pb_column .et_pb_module {
    margin-top: 50px !important;
  }

  .et_pb_bg_layout_light_phone.et_pb_module.et_pb_button,
  .et_pb_module .et_pb_bg_layout_light_phone.et_pb_button {
    color: #2ea3f2 !important;
  }
  .et_pb_bg_layout_dark_phone.et_pb_button,
  .et_pb_bg_layout_dark_phone.et_pb_module.et_pb_button,
  .et_pb_bg_layout_dark_phone .et_pb_more_button {
    color: inherit;
  }

  .et_pb_module .et_pb_bg_layout_dark_phone.et_pb_button {
    color: #fff !important;
  }

  .et_pb_bg_layout_dark_phone .et_pb_forgot_password a {
    color: #fff;
  }

  .et_pb_module.et_pb_text_align_left-phone {
    text-align: left;
  }

  .et_pb_module.et_pb_text_align_center-phone {
    text-align: center;
  }

  .et_pb_module.et_pb_text_align_right-phone {
    text-align: right;
  }

  .et_pb_module.et_pb_text_align_justified-phone {
    text-align: justify;
  }
}

@media (min-width: 768px) and (max-width: 980px) {
  [data-et-multi-view-load-tablet-hidden="true"]:not(.et_multi_view_swapped) {
    display: none !important;
  }
}

@media (max-width: 767px) {
  [data-et-multi-view-load-phone-hidden="true"]:not(.et_multi_view_swapped) {
    display: none !important;
  }
}
div.et_pb_section.et_pb_section_0 {
  background-image: url("../assets/images/404-page.jpg") !important;
  height: 100vh;
}

.et_pb_section_0.et_pb_section {
  padding-top: 13%;
  padding-bottom: 15.5%;
}

.et_pb_number_counter_0.et_pb_number_counter h3,
.et_pb_number_counter_0.et_pb_number_counter h1.title,
.et_pb_number_counter_0.et_pb_number_counter h2.title,
.et_pb_number_counter_0.et_pb_number_counter h4.title,
.et_pb_number_counter_0.et_pb_number_counter h5.title,
.et_pb_number_counter_0.et_pb_number_counter h6.title {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 31px;
  color: #7da741 !important;
  line-height: 1.8em;
}

.et_pb_number_counter_0.et_pb_number_counter .percent p {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 150px;
  color: #7da741 !important;
}

.et_pb_text_0 h1 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #ffffff !important;
  line-height: 1.6em;
  text-align: center;
}

.et_pb_text_0 {
  margin-top: 9px !important;
}

.et_pb_button_0_wrapper .et_pb_button_0,
.et_pb_button_0_wrapper .et_pb_button_0:hover {
  padding-top: 10px !important;
  padding-right: 30px !important;
  padding-bottom: 10px !important;
  padding-left: 30px !important;
}

body #page-container .et_pb_section .et_pb_button_0 {
  color: #ffffff !important;
  border-width: 3px !important;
  border-color: #ffffff;
  border-radius: 100px;
  letter-spacing: 0px;
  font-size: 17px;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600 !important;
  background-color: rgba(0, 0, 0, 0);
}

body #page-container .et_pb_section .et_pb_button_0:hover {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #7da741 !important;
  box-shadow: inset -2px -4px 20px 2px #93ae6b !important;
}

body #page-container .et_pb_section .et_pb_button_0:before,
body #page-container .et_pb_section .et_pb_button_0:after {
  display: none !important;
}

.et_pb_button_0 {
  transition: color 300ms ease 0ms, background-color 300ms ease 0ms,
    border 300ms ease 0ms, letter-spacing 300ms ease 0ms;
}

.et_pb_button_0,
.et_pb_button_0:after {
  transition: all 300ms ease 0ms;
}

@media only screen and (min-width: 981px) {
  .et_pb_row_0,
  body #page-container .et-db #et-boc .et-l .et_pb_row_0.et_pb_row,
  body.et_pb_pagebuilder_layout.single
    #page-container
    #et-boc
    .et-l
    .et_pb_row_0.et_pb_row,
  body.et_pb_pagebuilder_layout.single.et_full_width_page
    #page-container
    #et-boc
    .et-l
    .et_pb_row_0.et_pb_row {
    width: 90%;
    max-width: 1170px;
  }
}

@media only screen and (max-width: 980px) {
  .et_pb_row_0,
  body #page-container .et-db #et-boc .et-l .et_pb_row_0.et_pb_row,
  body.et_pb_pagebuilder_layout.single
    #page-container
    #et-boc
    .et-l
    .et_pb_row_0.et_pb_row,
  body.et_pb_pagebuilder_layout.single.et_full_width_page
    #page-container
    #et-boc
    .et-l
    .et_pb_row_0.et_pb_row {
    width: 90%;
    max-width: 90%;
  }

  .et_pb_text_0 {
    margin-top: 103px !important;
  }
}

@media only screen and (max-width: 767px) {
  .et_pb_number_counter_0.et_pb_number_counter h3,
  .et_pb_number_counter_0.et_pb_number_counter h1.title,
  .et_pb_number_counter_0.et_pb_number_counter h2.title,
  .et_pb_number_counter_0.et_pb_number_counter h4.title,
  .et_pb_number_counter_0.et_pb_number_counter h5.title,
  .et_pb_number_counter_0.et_pb_number_counter h6.title {
    font-size: 20px;
    line-height: 0.2em;
  }

  .et_pb_number_counter_0.et_pb_number_counter .percent p {
    font-size: 81px;
  }

  .et_pb_number_counter_0.et_pb_number_counter {
    margin-bottom: 163px !important;
  }

  .et_pb_text_0 h1 {
    font-size: 20px;
    line-height: 1.3em;
  }
}
.custom-background {
  background-color: #ffffff;
}
.woocommerce-product-gallery {
  opacity: 1 !important;
}

.percent-value {
  font-size: 80px;
}
</style>
